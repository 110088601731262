<template>
  <div>
    <div class="add-entrance" @click="showCreateParking" v-if="!createParkingWindow">
      + Добавить парковку
    </div>
    <v-card
      class="entrance-item"
      style="margin-top: 10px; width: 80%"
      v-if="createParkingWindow"
    >
      <v-card-text class="create-block">
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="newParkingName"
              class="newEntranceInput"
              label="Введите название парковки"
              type="text"
              color="#5b6cff"
              outlined
              filled
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="parkingSlots"
              label="Количество мест"
              type="number"
              color="#5b6cff"
              outlined
              filled
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <div class="btn-row">
        <v-btn
          @click="emitCreateParking"
          :disabled="(newParkingName.length < 1) || (parkingSlots.length < 1)"
          class="edit-btn"
          text
        >
          Добавить паркинг
        </v-btn>
        <v-btn
          @click="
            createParkingWindow = false;
            newParkingName = '';
            parkingSlots = '';
          "
          class="delete-btn"
          text
        >
          Отмена
        </v-btn>
      </div>
    </v-card>
  </div>
</template>

<script>
import { addParking } from '@/api/buildings';

export default {
  name: "addParking",
  props: {
    block: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    createParkingWindow: false,
    newParkingName: '',
    floors: [],
    floorCount: 1,
    parkingSlots: '',
  }),
  methods: {
    async showCreateParking() {
      this.createParkingWindow = true;
    },
    async emitCreateParking() {
      // console.log("block", this.block);
      // if (this.block?.block_id) {
      //   this.$emit("create", {
      //     entrance: this.newParkingName,
      //     floors: floors,
      //     places: places,
      //     block: this.block.block_id,
      //   });
      // } else {
      //   this.$emit("create", {
      //     entrance: this.newParkingName,
      //     floors: floors,
      //     places: places,
      //   });
      // }

      let res = await addParking ({
        entrance: this.newParkingName,
        floor_count: this.floorCount,
        place_count: this.parkingSlots,
      });
      console.log('res', res.data);
      if (res.data.message) {
        alert(res.data.message);
      } else {
        this.newParkingName = '';
        this.parkingSlots = '';
        this.createParkingWindow = false;
        this.$emit('parkingCreated');
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card__subtitle, .v-card__text, .v-card__title {
  padding: unset;
}
</style>