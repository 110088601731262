<template>
  <div class="backdrop" @click.self="closeModal">
    <div class="modal-sm add-parking-owner">
      <h2 class="ticket-title">Владелец места</h2>
      <div class="close" @click="closeModal">&#10005;</div>
      <div class="">
        <div class="address-row">

          <div v-if="!this.apt.isExt" class="address">
            <div class="type">Блок</div>
            <div class="num">{{apt.block.block}}</div>
          </div>

          <div v-if="this.apt.isExt" class="address">
            <div class="type">Внешняя парковка</div>
            <div class="num">{{apt.entrance}}</div>
          </div>

          <div v-if="this.apt.floor" class="address">
            <div class="type">Этаж</div>
            <div class="num">{{this.apt.floor}}</div>
          </div>

          <div class="address">
            <div class="type">Место</div>
            <div class="num">{{apt.number}}</div>
          </div>
        </div>
        
        <div style="padding-top: 10px">
          <v-text-field
            :class="getValidationClass('last_name')"
            label="Фамилия"
            type="text"
            id="last_name"
            name="last_name"
            autocomplete="last_name"
            v-model="form.last_name"
            :rules="rules"
            required
          ></v-text-field>
          <div class="error" v-if="errorMsg.last_name">{{errorMsg.last_name}}</div>
          
          <v-text-field
            :class="getValidationClass('first_name')"
            label="Имя"
            type="text"
            id="first_name"
            name="first_name"
            autocomplete="first_name"
            v-model="form.first_name"
            :rules="rules"
            required
          ></v-text-field>
          <span class="error" v-if="errorMsg.first_name">{{errorMsg.first_name}}</span>
          
          <v-text-field
            :class="getValidationClass('phone')"
            label="Номер телефона"
            type="phone"
            id="phone"
            name="phone"
            autocomplete="phone"
            v-model="form.phone"
            v-mask="'+ 7 (###) ###-##-##'"
            placeholder="+ 7 (___) ___-__-__"
            :rules="rules"
            required
          ></v-text-field>
          <span class="error" v-if="errorMsg.phone">{{errorMsg.phone}}</span>
          
          <v-text-field
            :class="getValidationClass('contract_number')"
            label="Лицевой счет"
            type="text"
            id="contract_number"
            name="contract_number"
            autocomplete="contract_number"
            v-model="form.contract_number"
            :rules="rules"
            required
          ></v-text-field>
          <span class="error" v-if="errorMsg.contract_number">{{errorMsg.contract_number}}</span>
          
          <v-text-field
            :class="getValidationClass('car_brand')"
            label="Марка машины"
            type="text"
            id="car_brand"
            name="car_brand"
            autocomplete="car_brand"
            v-model="form.car_brand"
            :rules="rules"
            required
          ></v-text-field>
          <span class="error" v-if="errorMsg.car_brand">{{errorMsg.car_brand}}</span>
          
          <v-text-field
            :class="getValidationClass('car_state_number')"
            label="Номер автомобиля"
            type="text"
            id="car_state_number"
            name="car_state_number"
            autocomplete="car_state_number"
            v-model="form.car_state_number"
            :rules="rules"
            required
          ></v-text-field>
          <span class="error" v-if="errorMsg.car_state_number">{{errorMsg.car_state_number}}</span>
          
          <button class="add-btn" @click="createParkingOwner">Добавить</button>
        </div>
      </div>


    </div>
  </div>
</template>

<script>
import {addParkingOwner,addPersonalAccount} from "@/api/buildings";
import { validationMixin } from 'vuelidate'
import {
  required,
  email,
  minLength,
  maxLength
} from 'vuelidate/lib/validators'
import { staffApi } from '@/api/staff';
import pregQuote from '@/snippets';

export default {
  props: {
    apt: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    floor:0,
    form:{
      first_name:'',
      last_name:'',
      phone:'',
      contract_number:'',
      car_state_number:'',
      car_brand:''
    },
    errorMsg:{
      first_name:'',
      last_name:'',
      phone:'',
      contract_number:'',
      car_state_number:'',
      car_brand:''
    },
    rules: [(value) => !!value || "Обязательное поле"],
  }),
  mounted() {
    if(this.apt.isExt){
      // this.floor = this.apt.block.floors[0].floor
    }
  },
  validations: {
    form: {
      last_name: {
        required,
        minLength: minLength(3)
      },
      lastName: {
        required,
        minLength: minLength(3)
      },
      age: {
        required,
        maxLength: maxLength(3)
      },
      gender: {
        required
      },
      email: {
        required,
        email
      }
    }
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    async createParkingOwner(){
      this.errorMsg.last_name = 'Необходимо заполнить это поле'

      const payload = {
        building_id:this.apt.building_id.toString(),
        first_name:this.form.first_name,
        last_name:this.form.last_name,
        phone:this.form.phone.replace(/[^0-9.]/g, ""),
        personal_account:this.form.contract_number,
        state_number:this.form.car_state_number,
        brand:this.form.car_brand
      }

      let personal_account_payload = {
        building: this.apt.building_id,
        personal_account:this.form.contract_number,
        provider: 1,
        last_name: this.form.last_name,
        first_name: this.form.first_name,
        address: "Внешняя парковка: " + this.apt.entrance + "Место: " + this.apt.number,
      };

      await addPersonalAccount(personal_account_payload);
      await addParkingOwner(payload).then( (res) => {
        this.$emit("close");
        this.$emit("create");
      }).catch( err => {
        console.error("error", err.response.data.msg)
      })
      // this.$emit("create", {...this.form, building_id:this.apt.building_id});
    },
    getValidationClass (fieldName) {
      const field = this.$v.form[fieldName]

      if (field) {
        return {
          'invalid': field.$invalid && field.$dirty
        }
      }
    },
  },
};
</script>

<style>
</style>