<template>
  <div class="chess-wrapper">
    <div class="grey-bg" :class="{ details: showParkingOwner }">
      <div class="">
        <!-- <div class="add-entrance ext" @click="toggleCreateParkingExt"> -->
          <!-- + Добавить парковку -->
        <!-- </div> -->
        <!-- <div class="block-title mb-6">Внешняя парковка</div> -->
        <AddParking
          @parkingCreated="init"
        />
        <div v-if="parkingLoading" class="parking-skeleton-wrapper">
          <v-skeleton-loader type="card-heading" class="parking-skeleton-header"></v-skeleton-loader>
          <v-skeleton-loader type="avatar@15" class="parking-skeleton-floor"></v-skeleton-loader>
          <v-skeleton-loader type="avatar@15" class="parking-skeleton-floor"></v-skeleton-loader>
        </div>
        <div>
          <template v-for="(parking,index) in extParkings">
            <!-- Карта шахматки в парковках -->
            <ParkingItem
              v-if="parking.entrance!=null"
              :key="parking.id"
              :floors="parking.floors"
              :isExt="true"
              :isBlock="false"
              :index="(index+1)"
              :block="parking"
              @hideSkeleton="parkingLoading = false"
              @showResident="toggleShowParkingOwner"
              @addResident="toggleAddParkingOwner"
              @delete="init"
            />
          </template>
        </div>
      </div>
      <!-- <v-divider style="margin-bottom: 50px"></v-divider>
      <div v-for="parking in blocksParkings" :key="parking.id">
        <div class="block">
          <div class="block-title">Блок {{ parking.block }}</div>
          <div class="free-parking">Пустых мест: {{ parking.free }}</div>
        </div>
        <AddParking
          :block="parking"
          @create="addParking"
        />

        <div class="flex-row flex-wrap">
          <ParkingItem
            :v-if="parking.floors.length!=0"
            :floors="parking.floors"
            :isExt="false"
            :block="parking"
            :isBlock="true"
            @showResident="toggleShowParkingOwner"
            @addResident="toggleAddParkingOwner"
          />
        </div>
      </div> -->
      <!-- TODO: ADD DELETE BLOCK BELOW-->
    </div>

    <!--TODO: сделать все нормально    -->
    <!--    Модалка для показа владельца парковки-->
    <div v-if="showParkingOwner">
      <div class="chess-side-modal">
        <div class="side-modal-wrap">
          <div class="close" @click="toggleShowParkingOwner">&#10005;</div>
          <h2 class="title">Персональные данные</h2>
          <div class="executor-info">
            <div class="avatar">
              <img src="@/assets/images/avatar.svg" alt="" />
            </div>
            <div class="text">
              <div class="name">{{ showingParkingOwner.first_name }} {{ showingParkingOwner.last_name }}</div>
              <div class="address-row">
                <div class="address">
                  <div class="type">{{ showingParkingOwner.isExt ? 'Внешняя парковка': 'Блок' }}</div>
                  <div class="num">{{showingParkingOwner.entrance}}</div>
                </div>
                <div class="address">
                  <div class="type">Этаж</div>
                  <div class="num">{{showingParkingOwner.floor}}</div>
                </div>
                <div class="address">
                  <div class="type">Место</div>
                  <div class="num">{{showingParkingOwner.number}}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="executor-description">
            <div class="item">
              <div class="left">Номер автомобиля</div>
              <div class="right">{{ showingParkingOwner.state_number }}</div>
            </div>
            <div class="item">
              <div class="left">Марка машины</div>
              <div class="right">{{ showingParkingOwner.brand }}</div>
            </div>
            <div class="item">
              <div class="left">Телефон</div>
              <div class="right">+{{ showingParkingOwner.phone }}</div>
            </div>
            <div class="item">
              <div class="left">Лицевой счет</div>
              <div class="right">{{ showingParkingOwner.personal_account }}</div>
            </div>
          </div>
          <div class="btn-row">
<!--            <div class="edit-btn">Редактировать</div>-->
            <div class="delete-btn" @click="deleteUser(showParkingOwner.apt)">Удалить</div>
          </div>
        </div>
      </div>
    </div>

    <!--    Модалка для добавления владельца парковки-->
    <div v-if="showAddParkingOwner">
      <AddParkingOwner :apt="addResidentApt" @close="toggleAddParkingOwner" @create="init"> </AddParkingOwner>
    </div>

    <!--    Модалка для добавления внешней парковки-->
    <div v-if="showCreateParkingExt">
      <CreateParking :apt="addResidentApt" :isBlock="false" @create="createParking"  @close="toggleCreateParkingExt" />
    </div>

    <!--    Модалка для добавления парковки внутри блока-->
    <div v-if="showCreateParkingBlock">
      <CreateParking :block="parkingInfo.block" :blockId="parkingInfo.block_id" :isBlock="true"
                     @close="toggleCreateParkingBlock" @create="createParking"/>
    </div>

  </div>
</template>

<script>
import AddParkingOwner from "@/components/modals/AddParkingOwner.vue";
import {getParkingListApi, createParking} from '@/api/buildings';
import {removePersonalApi,removeUserApi} from '@/api/users';
import CreateParking from "@/components/modals/CreateParking";
import AddParking from "@/components/modals/addParking"

export default {
  components: {
    CreateParking,
    AddParkingOwner,
    AddParking,
    ParkingItem: () => import('@/components/chess/ParkingItem'),
    addParking: () => import('@/components/modals/addParking'),
  },
  props: {
    type: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    showEditEntrance: false,
    showAddParkingOwner: false,
    showParkingOwner: false,
    showCreateParkingExt: false,
    showCreateParkingBlock: false,
    creatingParking: false,
    parkingLoading: false,
    showingParkingOwner: {last_name:"", first_name:"", phone:"", personal_account:"", brand:"", state_number:"",isExt:false},
    addResidentApt: {},
    blocksParkings: [],
    extParkings: [],
    parkingInfo: {}
  }),
  methods: {
    clickMe(){
      console.log(this.blocksParkings)
    },
    toggleCreateParkingExt() {
      this.showCreateParkingExt = !this.showCreateParkingExt;
    },
    toggleCreateParkingBlock(block) {
      this.showCreateParkingBlock = !this.showCreateParkingBlock;
      console.log(block);
      this.parkingInfo = block;
    },
    toggleAddParkingOwner(apt) {
      this.showAddParkingOwner = !this.showAddParkingOwner;
      this.addResidentApt = this.showAddParkingOwner ? { ...apt } : {};
    },
    toggleShowParkingOwner(apt) {
      if(!apt.target){
        this.showingParkingOwner.apt = apt;
        this.showingParkingOwner.last_name = apt.owner.last_name
        this.showingParkingOwner.first_name = apt.owner.first_name
        this.showingParkingOwner.phone = apt.owner.phone
        this.showingParkingOwner.personal_account = apt.owner.personal_account
        this.showingParkingOwner.isExt = apt.isExt;
        this.showingParkingOwner.number = apt.number;
        if (apt.parking_detail) {
          this.showingParkingOwner.brand = apt.parking_detail.brand;
          this.showingParkingOwner.state_number = apt.parking_detail.state_number;
          this.showingParkingOwner.floor = apt.parking_detail.building_id.floor;
          this.showingParkingOwner.entrance = apt.parking_detail.building_id.entrance;
        };
        this.showParkingOwner = true;
        this.$emit("resizeTabsRow");
      }else{
        this.showParkingOwner = !this.showParkingOwner;
        this.$emit("resizeTabsRow");
      }
      console.log('apt',this.showingParkingOwner.apt);
    },
    async deleteUser(apt){
      console.log('apt',apt);
      await removeUserApi(this.showingParkingOwner.apt.building_id);
      removePersonalApi(this.showingParkingOwner.apt.owner.personal_account);
      this.showParkingOwner=false;
      this.$emit("resizeTabsRow");
      this.init();
    },
    //создание парковки
    async createParking(data){
      console.log('new parking',data);
      if(data.isBlock){
        this.showCreateParkingBlock = !this.showCreateParkingBlock;
        createParking({
              "building_type":this.type.id, //тип паркинг
              "block": data.blockId, //выбранный блок
              "floor_count": data.blockProps.floorCount, //количество  этаей
              "place_count": data.blockProps.parkingCount //количество на этаже
        }).then(() => {
          this.init();
        });
      }else{
        this.showCreateParkingExt = !this.showCreateParkingExt;
        createParking({
            "building_type":this.type.id, //тип паркинг
            "parking_count": data.extProps.parkingCount, //количество паркинга
            "floor_count": data.extProps.floorCount, //количество этажей в каждом паркинге
            "place_count": data.extProps.placeCount //Кол-во парковочных мест на этаже
            // "start_floor": data.extProps.startFloor //с какого этажа начинается
        }).then(() => {
          this.init();
        });
      }
    },

    async getParkingList() {
      try {
        this.parkingLoading = true;
        this.$store.commit("ADD_LOADING", "PARKING_LOADING");
        const res = await getParkingListApi();
        this.parkings = res.data;

        //заполнение парковок в блоках
        // this.blocksParkings = res.data.filter((b =>{
        //   if(b.block !== null)
        //     return b;
        // })).map((b, i) => ({
        //   id: b.id || i,
        //   block: b.block,
        //   free: b.free,
        //   floors:b.floors,
        //   block_id:b.block_id
        // }));

        //заполнение внешних парковок
        this.extParkings = res.data.filter((b =>{
          if(b.block == null)
            return b;
        })).map((b, i) => ({
          id: "0"+i,
          free: b.free,
          floors:b.floors,
          number:(i+1),
          entrance: b.entrance
        }));
        console.log(res);
      } catch (err) {
        throw err;
      } finally {
        this.parkingLoading = false;
        this.$store.commit("DEL_LOADING", "PARKING_LOADING");
      };
    },
    init() {
      this.getParkingList();
    }
  },
  created() {
    this.init();
  },
}
</script>

<style>

</style>
