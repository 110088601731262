<template>
  <div class="backdrop" @click.self="closeModal">
    <div class="modal-sm" style="width: 357px">
      <div class="close" @click="closeModal">&#10005;</div>
      <div class="parking-wrap">
        <div class="create-parking">
          <div class="image"><img src="@/assets/images/car.svg" alt="" /></div>
          <h3 class="title">Создать парковку</h3>
          <v-subheader v-if="isBlock">в блоке {{block}}</v-subheader>
          <v-subheader v-if="!isBlock">за пределами блока</v-subheader>
        </div>
      </div>

      <div class="create-parking-modal">
        <!-- Создание парковки в блоке-->
        <v-stepper v-if="isBlock" v-model="blockProps.active">
          <v-stepper-items>
            <v-stepper-content step="first" v-model="blockProps.first">
              <div class="create-parking">
                <div class="block-count">
                  <div class="counter">
                    <div @click="blockProps.floorCount==1?null:blockProps.floorCount--" class="decrease">-</div>
                    <div class="num">
                      <input type="number" class="num appearance-none" v-model="blockProps.floorCount">
                    </div>
                    <div @click="blockProps.floorCount++" class="increase">+</div>
                  </div>
                  <p>Кол-во этажей в блоке</p>
                </div>
                <button class="pick-btn" @click="setBlockDone('first', 'second')">Далее</button>
              </div>
            </v-stepper-content>

            <v-stepper-content step="second" v-model="blockProps.second">
              <div class="create-parking">
                <div class="block-count">
                  <div class="counter">
                    <div @click="blockProps.parkingCount==1?null:blockProps.parkingCount--" class="decrease">-</div>
                    <div class="num">
                      <input type="number" class="num appearance-none" v-model="blockProps.parkingCount">
                    </div>
                    <div @click="blockProps.parkingCount++" class="increase">+</div>
                  </div>
                  <p>Кол-во парковочных мест на этаже</p>
                </div>
                <div class="btn-row">
                  <button class="back-btn" @click="setBlockDone('second', 'first')">Назад</button>
                  <button class="next-btn" @click="$emit('create', { isBlock, blockId, blockProps})">Создать</button>
                </div>
              </div>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>

        <!-- Создание внешней парковки-->
        <v-stepper v-if="!isBlock" v-model="extProps.active">
          <v-stepper-items>
            <v-stepper-content step="first" v-model="extProps.first">
              <div class="create-parking">
                <div class="block-count">
                  <div class="counter">
                    <div @click="extProps.parkingCount==1?null:extProps.parkingCount--" class="decrease">-</div>
                    <div class="num">
                      <input type="number" class="num appearance-none" v-model="extProps.parkingCount">
                    </div>
                    <div @click="extProps.parkingCount++" class="increase">+</div>
                  </div>
                  <p>Кол-во парковок</p>
                </div>
                <button class="pick-btn" @click="setExtDone('first', 'second')">Далее</button>
              </div>
            </v-stepper-content>

            <v-stepper-content step="second" v-model="extProps.second">
              <div class="create-parking">
                <div class="block-count">
                  <div class="counter">
                    <div @click="extProps.floorCount==1?null:extProps.floorCount--" class="decrease">-</div>
                    <div class="num">
                      <input type="number" class="num appearance-none" v-model="extProps.floorCount">
                    </div>
                    <div @click="extProps.floorCount++" class="increase">+</div>
                  </div>
                  <p>Кол-во этажей в каждой парковке</p>
                </div>
                <div class="btn-row">
                  <button class="back-btn" @click="setExtDone('second', 'first')">Назад</button>
                  <button class="next-btn" @click="setExtDone('second', 'third')">Далее</button>
                </div>
              </div>
            </v-stepper-content>

            <v-stepper-content step="third" v-model="extProps.third">
              <div class="create-parking">
                <div class="block-count">
                  <div class="counter">
                    <div @click="extProps.placeCount==1?null:extProps.placeCount--" class="decrease">-</div>
                    <div class="num">
                      <input type="number" class="num appearance-none" v-model="extProps.placeCount">
                    </div>
                    <div @click="extProps.placeCount++" class="increase">+</div>
                  </div>
                  <p>Кол-во парковочных мест на этаже</p>
                </div>
                <div class="btn-row">
                  <button class="back-btn" @click="setExtDone('third', 'second')">Назад</button>
                  <button class="next-btn" @click="$emit('create', { isBlock, extProps})">Создать</button>
                </div>
              </div>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    apt: {
      type: Object,
      default: () => {},
    },
    isBlock: Boolean,
    block:String,
    blockId:Number
  },
  data: () => ({
    counter: 1,
    active: 'first',
    first: false,
    second: false,
    third: false,
    blockProps:{
      floorCount:1,
      parkingCount:1,
      active: 'first',
      first: false,
      second: false,
    },
    extProps:{
      floorCount:1,
      parkingCount:1,
      placeCount:1,
      startFloor:1,
      active: 'first',
      first: false,
      second: false,
      third: false
    }
  }),
  methods: {
    closeModal() {
      this.$emit("close");
    },
    setBlockDone (id, index) {
      console.log(this)
      if (index) {
        this.blockProps.active = index
      }
      this[id] = true
    },
    setExtDone (id, index) {
      console.log(this)
      if (index) {
        this.extProps.active = index
      }
      this[id] = true
    },
  },
};
</script>

<style>
</style>